const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'خطط مرنة تنمو معك',
    subFlexiblePlansThatGrowsWithYou:
        'اختر خطة التسعير التي تلبّي احتياجات عملك. تقدم Traceforce باقات بأسعار مثالية ولكن بقيمة فائقة.',
    pricingPlans: 'خطط الأسعار',
    pricingThatWorksForEveryOne: 'أسعار تناسب الجميع',
    monthly: 'شهريًا',
    annualy: 'سنويًا',
    international: 'دولي',
    domestic: 'محلي',
    month: 'شهر',
    benifits: 'الفوائد',
    benifitsArr: [
        {
            title: 'الدعم الفني',
            description: 'فريق الدعم الفني المتخصص لدينا سيساعدك في كل خطوة',
        },
        {
            title: 'التكنولوجيا',
            description: 'تدريب خاص للبدء في استخدام المنصة بإشراف متخصصين',
        },
        {
            title: 'تحليل النمو',
            description: 'فريق مخصص لتقديم رؤى حول نموك كل شهر',
        },
        {
            title: 'المكافآت',
            description: 'مكافأة خاصة لأكثر الحسابات أداءً كل شهر',
        },
    ],
    allPlanIncludeTheseBenifits: 'جميع الخطط تشمل هذه الفوائد',
    someBenfitsOfMonthlyYearlySubscription: 'بعض فوائد الاشتراك الشهري/السنوي',
    fAQs: 'الأسئلة المتكررة',
    frequentlyAskedQuestions: 'الأسئلة الشائعة',
    someBaTypeOfQuestions: 'إليك بعض الأنواع الأساسية من الأسئلة لعملائنا',
    fAQArr: [
        {
            question: 'كيف يمكنني تسجيل شركتي؟',
            ans: 'يمكنك التسجيل في TraceForce في 3 خطوات سهلة على تطبيق الهاتف المحمول أو تطبيق الويب. فقط أضف اسمك، بريدك الإلكتروني، ورقم GST وستكون جاهزًا.',
        },
        {
            question: 'ما هي الطرق المختلفة التي يمكنني من خلالها توثيق تسجيل الدخول؟',
            ans: 'يوفر لك TraceForce خيارات متعددة لتسجيل الدخول، مثل خيار تسجيل الدخول عبر الهاتف المحمول، خيار المصادقة بالصور الشخصية، وخيار المصادقة بالذكاء الاصطناعي الأكثر أمانًا.',
        },
        {
            question: 'كيف يتم تسجيل الحضور؟',
            ans: 'يمكن للمستخدم تسجيل الحضور فقط عندما يكون داخل النطاق الجغرافي المخصص له. ولضمان عدم حدوث تسجيل زائف، لدينا طرق مصادقة مختلفة.',
        },
        {
            question: 'هل يمكننا تزوير الموقع أو تغيير الوقت لإكمال المهام أو تسجيل الحضور؟',
            ans: 'يرصد TraceForce أي نشاط غير مرغوب فيه أو مزيف ويقوم بتعطيل التطبيق وإخطار المستخدم.',
        },
        {
            question: 'هل يدعم TraceForce الموظفين عن بُعد؟',
            ans: 'يدعم TraceForce الموظفين المؤقتين بنظام الساعة، وبالتالي يحتسب الرواتب على أساس ساعات العمل الثابتة أو المرنة.',
        },
        {
            question: 'هل يمكنني تتبع فريق العمل الخاص بي عبر الهاتف المحمول؟',
            ans: 'يساعدك TraceForce في تتبع وإدارة فريق العمل الخاص بك عبر الهاتف المحمول والويب بطريقة زمنية، مما يمنحك عرضًا شاملاً ليومك.',
        },
        {
            question: 'ما هي الخدمات الأخرى التي يقدمها TraceForce إلى جانب HRMS؟',
            ans: 'يقدم TraceForce قائمة شاملة من الخدمات لجعل عمل المدير أو صاحب العمل أسهل ومساعدتهم بالإشعارات اللازمة فقط.',
        },
    ],
};
export default pricingPage;
