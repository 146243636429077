const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Flexible plans that grow with you',
    subFlexiblePlansThatGrowsWithYou: `Choose the pricing plan that meets your business needs.Traceforce offers price packages at optimal prices yet superior value.`,
    pricingPlans: 'Pricing Plans',
    pricingThatWorksForEveryOne: 'Pricing That Works For Everyone',
    monthly: 'Monthly',
    annualy: 'Annualy',
    international: 'International',
    domestic: 'Domestic',
    month: 'Month',
    benifits: 'Benifits',
    allPlanIncludeTheseBenifits: 'All plans include these benifits',
    someBenfitsOfMonthlyYearlySubscription: 'Some Benifits of the monthly/yearly subscription',
    benifitsArr: [
        {
            title: 'Technical Support',
            description: 'Our professional technical support team will assist you every step of the way.',
        },
        {
            title: 'Technology',
            description: 'Special training to get started with the platform from professionals.',
        },
        {
            title: 'Growth Analysis',
            description: 'A dedicated team to provide insights into your monthly growth.',
        },
        {
            title: 'Reward',
            description: 'A special reward for the best-performing account each month.',
        },
    ],
    fAQs: 'Frequently Asked Questions',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    someBaTypeOfQuestions: 'Here are some common types of questions from our customers',
    fAQArr: [
        {
            question: 'How can I sign up my firm ?',
            ans: 'You can sign up on TraceForce in 3 easy steps on our Mobile app or Web app. Just add your name, email and GST number and you are good to go.',
        },
        {
            question: 'What are the different ways by which I can authenticate my Log in?',
            ans: 'TraceForce provides you with multiple Clock In option For example we have default mobile Log in option, Selfie based option and last but not least the most secure AI based Face authentication.',
        },
        {
            question: 'How to mark Attendance?',
            ans: 'User can mark attendance only when the person is in the allocated Geofence assigned to him. Also to safeguard proxy we have different authentication methods in place',
        },
        {
            question: 'Can we mock Location or change time for Task completed or attendance marked?',
            ans: 'TraceForce detects any spam or unsolicited activity in place and would disable the application and also notify the user.',
        },
        {
            question: 'Does TraceForce support remote employees?',
            ans: 'TraceForce does support on Contract employees on Hourly basis and hence calculation payroll on the basis of Fixed Hour or Flexible Hour shift.',
        },
        {
            question: 'Can I track my workforce through mobile?',
            ans: 'TraceForce helps you in tracking and managing your workforce through mobile and web both in Timeline manner. Hence giving you a comprehensive details day’s view.',
        },
        {
            question: 'What are the other service TraceForce has to offer except from HRMS?',
            ans: 'TraceForce offers a very comprehensive list of services to make a manager or business owners job easy and help them with only the required notifications.',
        },
    ],
};

export default pricingPage;
