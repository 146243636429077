const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'உங்களுடன் வளரும் தானமாக அமைக்க கூடிய திட்டங்கள்',
    subFlexiblePlansThatGrowsWithYou:
        'உங்கள் வணிக தேவைகளை பூர்த்தி செய்யும் விலையில் திட்டத்தைத் தேர்ந்தெடுக்கவும். டிரேஸ்‌போர்ஸ் அதிகமான மதிப்புடன் ஏற்ற விலையில் தொகுப்புகளை வழங்குகிறது.',
    pricingPlans: 'விலை திட்டங்கள்',
    pricingThatWorksForEveryOne: 'எல்லோருக்கும் வேலை செய்யும் விலை',
    monthly: 'மாதாந்திர',
    annualy: 'வருடாந்திர',
    international: 'அந்தராஷ்டிர',
    domestic: 'உள்ளூரா',
    month: 'மாதம்',
    benifits: 'சலுகைகள்',
    allPlanIncludeTheseBenifits: 'அனைத்து திட்டங்களிலும் இந்த சலுகைகள் அடங்கும்',
    someBenfitsOfMonthlyYearlySubscription: 'மாதாந்திர/வருடாந்திர சந்தாதாரர்களுக்கான சில சலுகைகள்',
    benifitsArr: [
        {
            title: 'தொழில்நுட்ப ஆதரவு',
            description: 'எங்கள் தொழில்முறை தொழில்நுட்ப ஆதரவு குழு உங்களுக்கு ஒவ்வொரு கட்டமும் உதவியாக இருக்கும்.',
        },
        {
            title: 'தொழில்நுட்பம்',
            description: 'தொழில்முறையிலிருந்து துவங்க சிறப்பு பயிற்சி.',
        },
        {
            title: 'வளர்ச்சி பகுப்பாய்வு',
            description: 'உங்கள் மாதாந்திர வளர்ச்சிக்கு உள்ள நிச்சயிக்கப்பட்ட குழு.',
        },
        {
            title: 'பரிசு',
            description: 'ஒவ்வொரு மாதமும் சிறந்த செயல்பாட்டின் கணக்குக்கு ஒரு சிறப்பு பரிசு.',
        },
    ],
    fAQs: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
    frequentlyAskedQuestions: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
    someBaTypeOfQuestions: 'எங்கள் வாடிக்கையாளர்களிடமிருந்து சில பொதுவான கேள்விகள்',
    fAQArr: [
        {
            question: 'நான் என் நிறுவனத்தை எவ்வாறு பதிவு செய்யலாம்?',
            ans: 'நீங்கள் TraceForce இல் 3 எளிய படிகளில் பதிவு செய்யலாம் எங்கள் மொபைல் ஆப் அல்லது வலை ஆப்பில். உங்கள் பெயர், மின்னஞ்சல் மற்றும் GST எண் சேர்க்கவும், அதன் மூலம் நீங்கள் தொடங்க தயாராக இருக்கின்றீர்கள்.',
        },
        {
            question: 'நான் என் லாகினை எந்தவொரு விதமாகவும் அங்கீகரிக்க முடியும்?',
            ans: 'TraceForce பல கிளாக் இன் விருப்பங்களை வழங்குகிறது, உதாரணமாக, எங்களிடம் இயல்பான மொபைல் லாகின் விருப்பம், செல்பி அடிப்படையிலான விருப்பம் மற்றும் கடைசியாக, மிகவும் பாதுகாப்பான AI அடிப்படையிலான முக அங்கீகாரம் உள்ளது.',
        },
        {
            question: 'அடைவைக் குறிக்க எப்படி?',
            ans: 'பயனர் முன்னதாக ஒதுக்கப்பட்டுள்ள Geofence-இல் இருக்கும்போது மட்டும் அதைப் பதிவேற்ற முடியும். மேலும், பிரதிநிதியைத் தடுப்பதற்காக எங்களிடம் பல்வேறு அங்கீகார முறைமைகள் உள்ளன.',
        },
        {
            question: 'நாம் இடத்தை போலமாய் அல்லது வேலையை முடித்த நேரத்தை மாற்ற முடியுமா?',
            ans: 'TraceForce எதனையும் தவறான அல்லது அநாகரிகமான செயல்பாட்டை கண்டறிந்து, செயலியை முடித்து, பயனருக்கு அறிவிப்பை அனுப்பும்.',
        },
        {
            question: 'TraceForce தொலைதூர ஊழியர்களை ஆதரிக்குமா?',
            ans: 'TraceForce வாராந்திர ஊழியர்களை ஆதரிக்கின்றது மற்றும் கூட்டு ஊதியத்தை ஒதுக்கப்பட்ட மணிநேரம் அல்லது பரிமாற்ற மணிநேரம் அடிப்படையில் கணக்கிடுகிறது.',
        },
        {
            question: 'நான் என் தொழிலாளர்களை மொபைல் மூலம் கண்காணிக்க முடியுமா?',
            ans: 'TraceForce உங்களுக்கு உங்கள் தொழிலாளர்களை மொபைலும் வலைத்தளமும் மூலம் காலவரிசையில் கண்காணித்து நிர்வகிக்க உதவுகிறது, அதனால் ஒரு முழுமையான நாளின் விவரமான பார்வையை வழங்குகிறது.',
        },
        {
            question: 'TraceForce HRMS இல் தவிர வேறு எந்த சேவைகள் வழங்குகிறது?',
            ans: 'TraceForce பல சேவைகள் வழங்குகிறது, இது மேலாளர் அல்லது வணிக உரிமையாளர்களின் பணியை எளிதாக்கி, தேவையான அறிவிப்புகளோடு அவர்களை உதவுகிறது.',
        },
    ],
};
export default pricingPage;
