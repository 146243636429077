const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'আপনার সাথে বৃদ্ধি পায় এমন নমনীয় পরিকল্পনা',
    subFlexiblePlansThatGrowsWithYou:
        'আপনার ব্যবসায়িক প্রয়োজনীয়তা অনুযায়ী মূল্য পরিকল্পনা নির্বাচন করুন। ট্রেসফোর্স সেরা মূল্য সহ মূল্য প্যাকেজ সরবরাহ করে।',
    pricingPlans: 'মূল্য পরিকল্পনা',
    pricingThatWorksForEveryOne: 'সবাইর জন্য কার্যকরী মূল্য',
    monthly: 'মাসিক',
    annualy: 'বার্ষিক',
    international: 'আন্তর্জাতিক',
    domestic: 'দেশীয়',
    month: 'মাস',
    benifits: 'সুবিধা',
    allPlanIncludeTheseBenifits: 'সব পরিকল্পনায় এই সুবিধাগুলি অন্তর্ভুক্ত রয়েছে',
    someBenfitsOfMonthlyYearlySubscription: 'মাসিক/বার্ষিক সাবস্ক্রিপশনের কিছু সুবিধা',
    benifitsArr: [
        {
            title: 'প্রযুক্তিগত সহায়তা',
            description: 'আমাদের পেশাদার প্রযুক্তিগত সহায়তা দল আপনাকে প্রতিটি পদক্ষেপে সহায়তা করবে।',
        },
        {
            title: 'প্রযুক্তি',
            description: 'প্ল্যাটফর্মটি শুরু করতে বিশেষ প্রশিক্ষণ প্রদান।',
        },
        {
            title: 'বৃদ্ধির বিশ্লেষণ',
            description: 'মাসিক বৃদ্ধি সম্পর্কে তথ্য প্রদান করার জন্য একটি নিবেদিত দল।',
        },
        {
            title: 'পুরস্কার',
            description: 'প্রতি মাসে সেরা পারফর্মিং অ্যাকাউন্টের জন্য একটি বিশেষ পুরস্কার।',
        },
    ],
    fAQs: 'সাধারণত জিজ্ঞাসিত প্রশ্নাবলী',
    frequentlyAskedQuestions: 'সাধারণত জিজ্ঞাসিত প্রশ্নাবলী',
    someBaTypeOfQuestions: 'আমাদের গ্রাহকদের থেকে কিছু সাধারণ ধরনের প্রশ্ন',
    fAQArr: [
        {
            question: 'আমি কীভাবে আমার ফার্ম সাইন আপ করতে পারি?',
            ans: 'আপনি TraceForce-এ ৩টি সহজ ধাপে মোবাইল অ্যাপ বা ওয়েব অ্যাপে সাইন আপ করতে পারেন। শুধু আপনার নাম, ইমেইল এবং GST নম্বর যোগ করুন এবং আপনি প্রস্তুত।',
        },
        {
            question: 'আমি কীভাবে আমার লগইন প্রমাণীকরণ করতে পারি?',
            ans: 'TraceForce আপনাকে একাধিক লগইন অপশন সরবরাহ করে, যেমন ডিফল্ট মোবাইল লগইন অপশন, সেলফি ভিত্তিক অপশন এবং সর্বশেষে সবচেয়ে নিরাপদ AI ভিত্তিক ফেস অথেন্টিকেশন।',
        },
        {
            question: 'হাজিরা কীভাবে চিহ্নিত করব?',
            ans: 'ব্যবহারকারী কেবল তখনই হাজিরা চিহ্নিত করতে পারেন যখন ব্যক্তি তাকে বরাদ্দ করা জিওফেন্সের মধ্যে থাকবেন। এছাড়াও প্রক্সি প্রতিরোধের জন্য আমাদের বিভিন্ন প্রমাণীকরণ পদ্ধতি রয়েছে।',
        },
        {
            question:
                'আমরা কি অবস্থান জালিয়াতি করতে পারি বা কাজ সম্পন্ন বা হাজিরা চিহ্নিত করার সময় পরিবর্তন করতে পারি?',
            ans: 'TraceForce কোনো স্প্যাম বা অনাকাঙ্ক্ষিত কার্যকলাপ শনাক্ত করে এবং অ্যাপ্লিকেশন নিষ্ক্রিয় করে এবং ব্যবহারকারীকে জানায়।',
        },
        {
            question: 'TraceForce কি দূরবর্তী কর্মীদের সমর্থন করে?',
            ans: 'TraceForce ঘন্টাভিত্তিক চুক্তিভিত্তিক কর্মীদের সমর্থন করে এবং তাই নির্দিষ্ট বা নমনীয় সময়ের শিফট অনুযায়ী বেতন গণনা করে।',
        },
        {
            question: 'আমি কি মোবাইল দিয়ে আমার ওয়ার্কফোর্স ট্র্যাক করতে পারি?',
            ans: 'TraceForce আপনাকে মোবাইল এবং ওয়েব উভয় মাধ্যমেই আপনার ওয়ার্কফোর্স ট্র্যাক এবং পরিচালনা করতে সহায়তা করে, সময়রেখা আকারে। এটি আপনাকে একটি সার্বিক দিনের বিস্তারিত উপস্থাপন করে।',
        },
        {
            question: 'HRMS ছাড়া TraceForce কি অন্যান্য পরিষেবা প্রদান করে?',
            ans: 'TraceForce একটি ব্যাপক পরিষেবা তালিকা প্রদান করে যা ম্যানেজার বা ব্যবসার মালিকদের কাজ সহজ করে এবং কেবল প্রয়োজনীয় বিজ্ঞপ্তিগুলিতে সাহায্য করে।',
        },
    ],
};
export default pricingPage;
