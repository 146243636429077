import { useEffect, useState } from 'react';

import AOS from 'aos';

// routes
import Routes from './routes/Routes';

// helpers
import { configureFakeBackend } from './helpers';

// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';
import { useDispatch } from 'react-redux';
import { setReduxCountry } from 'reduxFolder/uiSlice';

const App = () => {
    const dispatch = useDispatch();
    const [country, setCountry] = useState('');

    useEffect(() => {
        AOS.init();

        // Fetch user's location
        fetch('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572') // Replace with your chosen API
            .then((response) => response.json())
            .then((data) => {
                // console.log('I am free free data', data);
                setCountry(data.country_name);
            })
            .catch((error) => console.error('Error fetching location:', error));
    }, []);

    useEffect(() => {
        dispatch(setReduxCountry(country));
    }, [country]);

    configureFakeBackend();
    return (
        <>
            <Routes />
        </>
    );
};

export default App;
