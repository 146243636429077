const pricingPage = {
    flexiblePlansThatGrowsWithYou: 'Planos Flexíveis que Crescem com Você',
    subFlexiblePlansThatGrowsWithYou:
        'Escolha o plano de preços que atende às necessidades do seu negócio. A Traceforce oferece pacotes de preços com valores otimizados e superior valor.',
    pricingPlans: 'Planos de Preços',
    pricingThatWorksForEveryOne: 'Preços que Funcionam para Todos',
    monthly: 'Mensal',
    annualy: 'Anual',
    international: 'Internacional',
    domestic: 'Nacional',
    month: 'Mês',
    benifits: 'Benefícios',
    allPlanIncludeTheseBenifits: 'Todos os planos incluem estes benefícios',
    someBenfitsOfMonthlyYearlySubscription: 'Alguns Benefícios da Assinatura Mensal/Anual',
    benifitsArr: [
        {
            title: 'Suporte Técnico',
            description: 'Nossa equipe profissional de suporte técnico ajudará você em cada etapa do processo.',
        },
        {
            title: 'Tecnologia',
            description: 'Treinamento especial para começar com a plataforma a partir de profissionais.',
        },
        {
            title: 'Análise de Crescimento',
            description: 'Uma equipe dedicada para fornecer insights sobre seu crescimento mensal.',
        },
        {
            title: 'Recompensa',
            description: 'Uma recompensa especial para a conta com o melhor desempenho a cada mês.',
        },
    ],
    fAQs: 'Perguntas Frequentes',
    frequentlyAskedQuestions: 'Perguntas Frequentes',
    someBaTypeOfQuestions: 'Aqui estão alguns tipos comuns de perguntas de nossos clientes',
    fAQArr: [
        {
            question: 'Como posso registrar minha empresa?',
            ans: 'Você pode se registrar no TraceForce em 3 etapas fáceis em nosso aplicativo móvel ou aplicativo web. Basta adicionar seu nome, e-mail e número GST e você estará pronto para começar.',
        },
        {
            question: 'Quais são as diferentes formas de autenticar meu login?',
            ans: 'O TraceForce oferece várias opções de Clock In, por exemplo, temos a opção de login móvel padrão, a opção baseada em selfie e, por último, a autenticação facial baseada em IA, a mais segura.',
        },
        {
            question: 'Como marcar a presença?',
            ans: 'O usuário pode marcar a presença apenas quando estiver dentro da Geofence alocada a ele. Além disso, para evitar proxy, temos diferentes métodos de autenticação em vigor.',
        },
        {
            question: 'Podemos simular a localização ou alterar o horário para a tarefa concluída ou presença marcada?',
            ans: 'O TraceForce detecta qualquer atividade de spam ou não solicitada e desativa o aplicativo, além de notificar o usuário.',
        },
        {
            question: 'O TraceForce suporta funcionários remotos?',
            ans: 'O TraceForce suporta funcionários contratados por hora e calcula a folha de pagamento com base em horas fixas ou turnos flexíveis.',
        },
        {
            question: 'Posso rastrear minha força de trabalho pelo celular?',
            ans: 'O TraceForce ajuda você a rastrear e gerenciar sua força de trabalho por meio do celular e da web, ambos em formato de linha do tempo, oferecendo uma visão detalhada do dia.',
        },
        {
            question: 'Quais são outros serviços que o TraceForce oferece além do HRMS?',
            ans: 'O TraceForce oferece uma lista abrangente de serviços para facilitar o trabalho de gerentes ou proprietários de negócios e ajudá-los com apenas as notificações necessárias.',
        },
    ],
};
export default pricingPage;
